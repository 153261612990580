export function getDeviceId(): string {
  let deviceId = localStorage.getItem('amplitude-device-id')
  if (deviceId !== null) return deviceId

  deviceId = window.crypto
    .getRandomValues(new Uint32Array(5))
    .reduce((all, current) => all.concat(current.toString(16)), '')

  localStorage.setItem('amplitude-device-id', deviceId)

  return deviceId
}
