import '@/styles/main.css'
import '@/config/tailwind-variants.config'

import { DefaultSeo } from 'next-seo'
import { AppProps } from 'next/app'
import dynamic from 'next/dynamic'
import { Inter, Roboto } from 'next/font/google'
import localFont from 'next/font/local'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

import IS_SERVER from '@/constants/isServer'
import { ExperimentProvider } from '@/contexts/amplitude'
import { getDeviceId } from '@/services/amplitude/getDeviceId'
import { getSessionId } from '@/services/amplitude/getSessionId'
import {
  ampli as amplitudeAnalytics,
  DefaultConfiguration,
  ensureGetUserId
} from '@/utils/amplitude'
import { initializeGA, pageView } from '@/utils/analytics'
import DatadogInit from '@/utils/datadog-init'
import { getAndSaveSearchParams } from '@/utils/searchParams'
import { Experiment, ExperimentClient } from '@amplitude/experiment-js-client'

import useChat from '../hooks/useChat'

const WhatsappButton = dynamic(() => import('@/components/WhatsappButton'))

let experiment: ExperimentClient

const inter = Inter({
  weight: ['300', '400', '500', '600', '700', '800', '900'],
  preload: true,
  display: 'swap',
  subsets: ['latin'],
  variable: '--font-inter'
})

const roboto = Roboto({
  preload: true,
  display: 'swap',
  weight: ['300', '400', '500', '700', '900'],
  subsets: ['latin'],
  variable: '--font-roboto'
})

const myFont = localFont({
  src: [
    {
      path: '../assets/fonts/TonCondensed-Thin.woff2',
      weight: '100'
    },
    {
      path: '../assets/fonts/TonCondensed-Extralight.woff2',
      weight: '200'
    },
    {
      path: '../assets/fonts/TonCondensed-Light.woff2',
      weight: '300'
    },
    {
      path: '../assets/fonts/TonCondensed-Regular.woff2',
      weight: 'normal'
    },
    {
      path: '../assets/fonts/TonCondensed-Medium.woff2',
      weight: '500'
    },
    {
      path: '../assets/fonts//TonCondensed-Semibold.woff2',
      weight: '600'
    },
    {
      path: '../assets/fonts/TonCondensed-Bold.woff2',
      weight: '700'
    },
    {
      path: '../assets/fonts/TonCondensed-Extrabold.woff2',
      weight: '800'
    },
    {
      path: '../assets/fonts/TonCondensed-Black.woff2',
      weight: '900'
    }
  ],
  display: 'swap',
  variable: '--ton-condensed'
})

export default function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter()

  useChat()

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      pageView(url)
    }

    router.events.on('routeChangeComplete', handleRouteChange)

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  const [isWhatsappButton, setIsWhatsappButton] = useState(false)

  useEffect(() => {
    const routerUtm = router.query.utm_source

    const utmSources = [
      'revendedor',
      'fb',
      'google',
      'tiktok',
      'criteo',
      'bing'
    ]

    const disableChat = !utmSources.includes(routerUtm as string)

    setIsWhatsappButton(disableChat)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query.utm_source])

  useEffect(() => {
    getAndSaveSearchParams()
    Promise.all([initializeGA()])

    amplitudeAnalytics.load({
      client: {
        apiKey: process.env.NEXT_PUBLIC_AMPLITUDE_ANALYTICS_API_KEY as string,
        configuration: {
          ...DefaultConfiguration,
          logLevel: 3,
          userId: ensureGetUserId(),
          sessionId: getSessionId(),
          deviceId: getDeviceId()
        }
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [, setHistory] = useState<string[]>([router.asPath])

  useEffect(() => {
    if (IS_SERVER) return

    const handleRouteChange = (url: string) => {
      setHistory((history: string[]) => {
        sessionStorage?.setItem(
          'amplitude-source',
          window.location.host + history[history.length - 1]
        )

        return [...history, url]
      })
    }

    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  if (!IS_SERVER && !experiment) {
    const amplitudeExperimentsApiKey =
      process.env.NEXT_PUBLIC_AMPLITUDE_EXPERIMENTS_API_KEY
    if (amplitudeExperimentsApiKey) {
      experiment = Experiment.initializeWithAmplitudeAnalytics(
        amplitudeExperimentsApiKey,
        {}
      )
    } else {
      console.error('Amplitude Experiments API Key is not defined')
    }
  }

  return (
    <>
      <Head>
        <meta charSet="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="theme-color" content="#00C700" />
        <meta name="url" content="https://www.ton.com.br/" />

        <meta
          name="google-site-verification"
          content="O1OQW32uto877Hm9dOMWb5q3AgEFyWaPU-jElYkYazs"
        />
      </Head>

      <DatadogInit />

      <DefaultSeo
        openGraph={{
          type: 'website',
          locale: 'pt_BR',
          url: 'https://ton.com.br/',
          site_name: 'Ton',
          images: [
            {
              url: 'https://res.cloudinary.com/dunz5zfpt/image/upload/v1/site-ton/OGImage.png',
              width: 800,
              height: 600
            }
          ]
        }}
      />
      <main
        className={`${inter.variable} ${roboto.variable} ${myFont.variable} font-default`}
      >
        <div id="menuMobile" />
        <div id="modal" />
        <div id="drawer" />
        <div id="webchat" />
        <GoogleReCaptchaProvider
          reCaptchaKey={process.env.NEXT_PUBLIC_RECAPTCHA!}
          scriptProps={{
            async: false,
            defer: false,
            appendTo: 'head',
            nonce: undefined
          }}
        >
          <ExperimentProvider
            value={{
              experiments: experiment,
              analytics: amplitudeAnalytics
            }}
          >
            {isWhatsappButton && <WhatsappButton />}
            <Component {...pageProps} />
          </ExperimentProvider>
        </GoogleReCaptchaProvider>
      </main>
    </>
  )
}
