export function getEventId(): number {
  const lastEventId = localStorage.getItem('amplitude-last-event-id')
  if (lastEventId !== null) {
    const nextEventId = String(Number(lastEventId) + 1)
    localStorage.setItem('amplitude-last-event-id', nextEventId)
    return Number(nextEventId)
  }

  // @see about this number in https://www.docs.developers.amplitude.com/analytics/apis/http-v2-api/#keys-for-the-event-argument
  // its not zero because amplitude starts with 1 when use anonymous guide in browser or is first time to track...
  // None user will have a number greater than 19707001
  const aBiggerRandomNumberToDistinguishEventsWithSameUserAndTimestamp = 19707001
  localStorage.setItem(
    'amplitude-last-event-id',
    String(aBiggerRandomNumberToDistinguishEventsWithSameUserAndTimestamp)
  )

  return aBiggerRandomNumberToDistinguishEventsWithSameUserAndTimestamp
}
