export const GA_SETTINGS = {
  UACode: 'UA-104644236-1',
  debug: false
}

export const CATEGORIES = {
  NAV_HEADER: 'Nav Header',
  CTA_HEADER: 'CTA Header',
  CTA_CARD: 'CTA Card',
  SIDE_CHAT: 'Chat lateral',
  DOWNLOAD_APP: 'Download App',
  SITE_STONE: 'Site Stone',
  INBOUND_LEAD: 'Inbound Lead',
  INBOUND_FORM: 'Inbound Form'
}

export const ACTIONS = {
  ADQUIRA: 'Adquira',
  TRACK_ORDER: 'Rastrear pedido',
  CLICK_PHONE: 'Clique telefone',
  CLICK_WHATSAPP: 'Clique Whatsapp',
  ORDER_NOW: 'Pedir Agora',
  T1_MEGA: 'T1 Mega',
  T1_CHIP_MEGA: 'T1 Chip Mega',
  T2_MEGA: 'T2 Mega',
  T3_MEGA: 'T3 Mega',
  T3_SMART_MEGA: 'T3 Smart Mega',
  T1: 'T1',
  T1_CHIP: 'T1 Chip',
  T2: 'T2',
  T3: 'T3',
  T3_SMART: 'T3 Smart',
  OPEN_CHAT: 'Abriu chat',
  CLOSE_CHAT: 'Fechou chat',
  GOOGLE_PLAY: 'Google Play',
  APP_STORE: 'App Store',
  DOWNLOAD_NOW: 'Baixe Agora',
  OPEN: 'Abrir',
  CLOSE: 'Fechar',
  T1_PROMO: 'T1 PromoTon',
  T1_CHIP_PROMO: 'T1 Chip PromoTon',
  T2_PROMO: 'T2 PromoTon',
  T3_PROMO: 'T3 PromoTon',
  T3_SMART_PROMO: 'T3 Smart PromoTon',
  ERROR: 'ERROR'
}

export const CHANNEL_LABELS = {
  PHONE: 'Telefone',
  WHASTSAPP: 'WhatsApp'
}

export const EVENTS = {
  INBOUND_CALLING: 'inbound_calling',
  INBOUND_FORM_SUBMITTED: 'inbound_form_submitted'
}
