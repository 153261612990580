import { datadogRum } from '@datadog/browser-rum'
import * as client from 'react-dom/client'
import pkg from '../../package.json'

const env =
  process.env.SERVICES_ENV ||
  process.env.NEXT_PUBLIC_ENVIRONMENT_NAME ||
  'production'

datadogRum.init({
  env,
  applicationId: '48133e31-81d6-44b5-b853-374f14af2c7a',
  clientToken: 'pubc00786f0ac93c54ca5ba3e501d9d2c2d',
  site: 'datadoghq.com',
  service: 'datadog-rum-landing-ton',
  sessionSampleRate: 100,
  sessionReplaySampleRate: 2,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input',
  version: pkg.version
})

datadogRum.setGlobalContext({
  owner: 'codeowners-customer-platforms',
  project: 'landing-ton',
  'cost-center': '860290032',
  support: 'codeowners-customer-platforms'
})

const oldClientHydrateRoot = client.hydrateRoot

// @ts-ignore
client.hydrateRoot = new Proxy(oldClientHydrateRoot, {
  apply: (wrappingTarget, thisArg, args) => {
    const oldOnRecoverableError = args[2].onRecoverableError

    args[2].onRecoverableError = new Proxy(oldOnRecoverableError, {
      apply: (_, __, inner_args) => {
        const error = inner_args[0]
        const errorInfo = inner_args[1]
        const renderingError = new Error(error.message)

        renderingError.name = `ReactRenderingError`
        renderingError.stack = errorInfo.componentStack || undefined
        renderingError.cause = error

        datadogRum.addError(renderingError, {
          framework: 'react'
        })
      }
    })

    // @ts-ignore
    return wrappingTarget.apply(thisArg, args)
  }
})

export default function DatadogInit() {
  return null
}
