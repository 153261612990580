const ENABLED_SEARCH_PARAMS = ['utm_', 'gclid', 'fbclid', 'coupon', 'referrer']

export const mergeUrlWithSearchParams = (
  url: string,
  searchParams: { [key: string]: string }
) => {
  const newUrl = new URL(url)
  const newUrlSearchParams = new URLSearchParams(newUrl.hash || newUrl.search)

  const newParams: { key: string; value: string }[] = []

  for (const params of Array.from(newUrlSearchParams)) {
    const [key, value] = params
    const splittedKey = key.split('?')

    if (splittedKey.length > 1) {
      newParams.push({ key: splittedKey[1], value })
    } else {
      newParams.push({ key, value })
    }
  }

  Object.entries(searchParams).forEach(([key, value]) => {
    const newParamsIndex = newParams?.findIndex(
      (newParam) => newParam.key === key
    )

    if (newParamsIndex > -1) {
      newParams[newParamsIndex] = {
        key,
        value
      }
    } else {
      newParams.push({ key, value })
    }
  })

  const queryParams = newParams.reduce((params, newParam) => {
    const { key, value } = newParam

    if (params === '') {
      return `?${key}=${value}`
    }

    return `${params}&${key}=${value}`
  }, '')

  return `${url.split('?')[0]}${queryParams}`
}

export const getAndSaveSearchParams = () => {
  if (window.location.search === null) return null

  const [, query] = window.location.search.split('?')

  if (query) {
    const urlSearchParams = new URLSearchParams(window.location.search)
    const params = Object.fromEntries(urlSearchParams.entries())

    const filteredSearchParams = Object.entries(params).filter(([key]) =>
      ENABLED_SEARCH_PARAMS.some((param) => key.includes(param))
    )

    const searchParams = Object.fromEntries(filteredSearchParams)

    sessionStorage.setItem('searchParams', JSON.stringify(searchParams))

    return searchParams
  }

  const searchParams = sessionStorage.getItem('searchParams')

  if (searchParams) {
    return JSON.parse(searchParams)
  }

  return null
}

export function getSearchParams(urlParams: string) {
  if (!urlParams) return {}

  const qs = new URLSearchParams(urlParams)

  return Object.fromEntries(qs)
}
