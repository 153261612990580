export const SESSION_STORAGE_SESSION_ID_KEY = 'amplitude-user-id' as const

export function getSessionId(): number {
  const existingSessionId = sessionStorage.getItem(
    SESSION_STORAGE_SESSION_ID_KEY
  )

  if (existingSessionId) {
    return Number(existingSessionId)
  }

  const now = new Date()
  const unixTimeMilliseconds = now.getTime()

  const newSessionId = String(unixTimeMilliseconds)
  sessionStorage.setItem(SESSION_STORAGE_SESSION_ID_KEY, newSessionId)

  return Number(newSessionId)
}
