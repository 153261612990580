import { defaultConfig } from 'tailwind-variants'

defaultConfig.twMergeConfig = {
  classGroups: {
    'font-size': [
      'text-10',
      'text-12',
      'text-14',
      'text-16',
      'text-18',
      'text-20',
      'text-24',
      'text-28',
      'text-32',
      'text-36',
      'text-40',
      'text-48',
      'text-56'
    ],
    'border-radius': [
      'rounded-b-none',
      'rounded-b-4',
      'rounded-b-8',
      'rounded-b-10',
      'rounded-b-12',
      'rounded-b-14',
      'rounded-b-16',
      'rounded-b-18',
      'rounded-b-20',
      'rounded-b-24',
      'rounded-b-28',
      'rounded-b-32',
      'rounded-b-36',
      'rounded-b-40',
      'rounded-br-none',
      'rounded-br-4',
      'rounded-br-8',
      'rounded-br-10',
      'rounded-br-12',
      'rounded-br-14',
      'rounded-br-16',
      'rounded-br-18',
      'rounded-br-20',
      'rounded-br-24',
      'rounded-br-28',
      'rounded-br-32',
      'rounded-br-36',
      'rounded-br-40',
      'rounded-bl-none',
      'rounded-bl-4',
      'rounded-bl-8',
      'rounded-bl-10',
      'rounded-bl-12',
      'rounded-bl-14',
      'rounded-bl-16',
      'rounded-bl-18',
      'rounded-bl-20',
      'rounded-bl-24',
      'rounded-bl-28',
      'rounded-bl-32',
      'rounded-bl-36',
      'rounded-bl-40',
      'rounded-t-none',
      'rounded-t-4',
      'rounded-t-8',
      'rounded-t-10',
      'rounded-t-12',
      'rounded-t-14',
      'rounded-t-16',
      'rounded-t-18',
      'rounded-t-20',
      'rounded-t-24',
      'rounded-t-28',
      'rounded-t-32',
      'rounded-t-36',
      'rounded-t-40',
      'rounded-tr-none',
      'rounded-tr-4',
      'rounded-tr-8',
      'rounded-tr-10',
      'rounded-tr-12',
      'rounded-tr-14',
      'rounded-tr-16',
      'rounded-tr-18',
      'rounded-tr-20',
      'rounded-tr-24',
      'rounded-tr-28',
      'rounded-tr-32',
      'rounded-tr-36',
      'rounded-tr-40',
      'rounded-tl-none',
      'rounded-tl-4',
      'rounded-tl-8',
      'rounded-tl-10',
      'rounded-tl-12',
      'rounded-tl-14',
      'rounded-tl-16',
      'rounded-tl-18',
      'rounded-tl-20',
      'rounded-tl-24',
      'rounded-tl-28',
      'rounded-tl-32',
      'rounded-tl-36',
      'rounded-tl-40',
      'rounded-r-none',
      'rounded-r-4',
      'rounded-r-8',
      'rounded-r-10',
      'rounded-r-12',
      'rounded-r-14',
      'rounded-r-16',
      'rounded-r-18',
      'rounded-r-20',
      'rounded-r-24',
      'rounded-r-28',
      'rounded-r-32',
      'rounded-r-36',
      'rounded-r-40',
      'rounded-l-none',
      'rounded-l-4',
      'rounded-l-8',
      'rounded-l-10',
      'rounded-l-12',
      'rounded-l-14',
      'rounded-l-16',
      'rounded-l-18',
      'rounded-l-20',
      'rounded-l-24',
      'rounded-l-28',
      'rounded-l-32',
      'rounded-l-36',
      'rounded-l-40'
    ]
  }
}
