import Cookies from 'js-cookie'

export interface PrivacyCookies {
  consents: { [key: string]: number }
  location: string
  lang: string
  gpcInBrowserOnConsent: boolean
  gpcStatusInPortalOnConsent: boolean
  status: string
  implicitConsent: boolean
}

export default function cookieConsentAccepted(): boolean {
  const privacyCookies = Cookies.get('__privaci_cookie_consents')

  if (privacyCookies === undefined) return false

  const privacyCookiesParsed: PrivacyCookies = JSON.parse(privacyCookies)

  const consents = Object.values(privacyCookiesParsed.consents)

  const allTermsConsented = consents.every((consent) => consent === 1)

  return allTermsConsented
}
