import { useRouter } from 'next/router'
import { useCallback, useEffect } from 'react'

import { sendEvent } from '../utils/analytics'

function widgetLoader() {
  const chatScript = document.createElement('script')
  chatScript.type = 'text/javascript'
  chatScript.src =
    'https://storage.googleapis.com/push-webchat/widget-latest.js'
  chatScript.async = true

  document.head.appendChild(chatScript)
}

const useChat = () => {
  const router = useRouter()

  const startWidget = useCallback(() => {
    const interval = setInterval(() => {
      if (window.WebChat) {
        clearInterval(interval)

        window.WebChat.default.init({
          selector: '#webchat',
          initPayload: '',
          channelUuid: 'd2a6091a-8505-4554-880a-7e283317a43c',
          title: 'Oi, Eu sou o Ton!',
          host: 'https://new.push.al',
          socketUrl: 'https://socket.push.al',
          profileAvatar:
            'https://res.cloudinary.com/dunz5zfpt/image/upload/v1/site-ton/on-logo.png',
          openLauncherImage:
            'https://res.cloudinary.com/dunz5zfpt/image/upload/v1/site-ton/on-logo.png',
          inputTextFieldHint: 'Digite sua mensagem'
        })

        window.WebChat.show()
      }
    }, 300)

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [])

  useEffect(() => {
    const routerUtm = router.query.utm_source

    const utmSources = [
      'revendedor',
      'fb',
      'google',
      'tiktok',
      'criteo',
      'bing'
    ]

    const disableChat = !utmSources.includes(routerUtm as string)

    if (!disableChat && routerUtm !== 'undefined') {
      widgetLoader()

      startWidget()

      addEventListener('click', (e) => {
        if (e.target) {
          const target = e.target as HTMLElement

          const isChatOpenButton =
            target.classList.contains('push-open-launcher')

          const isChatCloseButton =
            target.classList.contains('push-launcher') ||
            target.classList.contains('push-close-launcher') ||
            target.classList.contains('push-close') ||
            target.classList.contains('push-close-button')

          if (isChatCloseButton || isChatOpenButton) {
            sendEvent({
              action: isChatOpenButton ? 'OPEN_CHAT' : 'CLOSE_CHAT',
              category: 'SIDE_CHAT'
            })
          }
        }
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query.utm_source])
}

export default useChat
