import ReactGA from 'react-ga'

import {
  ACTIONS,
  CATEGORIES,
  CHANNEL_LABELS,
  EVENTS,
  GA_SETTINGS
} from '../constants/analytics'
import cookieConsentAccepted from './cookie-consent-checker'

let GA_INITIALIZED = false

const initializeGA = async () => {
  if (GA_INITIALIZED) return

  GA_INITIALIZED = true

  const { UACode, debug } = GA_SETTINGS

  ReactGA.initialize(UACode, {
    debug
  })
}

export type CategoriesType = keyof typeof CATEGORIES
export type ActionsType = keyof typeof ACTIONS

type SendEvent = {
  category: CategoriesType
  action: ActionsType
  label?: string
  value?: number
}

const sendEvent = ({ category, action, label = '', value = 0 }: SendEvent) => {
  if (!cookieConsentAccepted) return

  ReactGA.event({
    category: CATEGORIES[category],
    action: ACTIONS[action],
    label,
    value
  })
}

export const handlePhoneOnInboundSiteClick = (page: string) => {
  ReactGA.event({
    category: CATEGORIES.INBOUND_LEAD,
    action: CHANNEL_LABELS.PHONE,
    label: `Página ${page}`
  })

  window.dataLayer?.push({
    event: EVENTS.INBOUND_CALLING,
    channel: CHANNEL_LABELS.PHONE
  })
}

const pageView = (path: string) => ReactGA.pageview(path)

export { initializeGA, pageView, sendEvent }
